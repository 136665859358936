import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

const PrivacyPolicy = () => (
  <Layout>
    <div style={{
      display: `flex`,
      justifyContent: `center`,
      alignItems: `center`,
    }}>
      <h1>Privacy Policy of Zodiactive</h1>
    </div>
    <p>This Application only uses limited Personal Data at runtime from its Users, and does not transmit or store it anywhere else.</p>
    <p>This document can be printed for reference by using the print command in the settings of any browser.</p>
    <h2>Owner and Data Controller</h2>
<p>Taogi Limited,
71-75 Shelton Street,
London,
Greater London,
WC2H 9JQ</p>
<p>Owner contact email: info@taogidesign.com</p>
<h2>Types of Data collected</h2>
<p>The Application requests Contacts permission to be able to display information relevant to the contacts present on the device on which the Application is running. The Contacts data is only accessed by the Application on the device at runtime, and the Application does not transmit or store the Contacts data anywhere else.</p>
<h2>Device permissions for Personal Data access</h2>
<p>The Application may request permissions that allow it to access the User's device Data as described above.</p>
<p>By default, these permissions must be granted by the User before the respective information can be accessed. Once the permission has been given, it can be revoked by the User at any time. In order to revoke these permissions, Users may refer to the device settings or contact the Owner for support at the contact details provided in the present document.</p>
<p>The exact procedure for controlling app permissions may be dependent on the User's device and software.</p>
<p>Please note that the revoking of such permissions might impact the proper functioning of this Application.</p>
<p>If User grants any of the permissions listed below, the respective Personal Data may be processed (i.e accessed to, modified or removed) by this Application.</p>
<h3>Contacts permission</h3>
<p>Used for reading contacts and profiles on the User's device.</p>
<h2>The rights of Users</h2>
<p>Users may discontinue use of the Application at any time. Since no data is transferred off the device, uninstalling or deleting the Application will discontinue its use.</p>
<h2>Additional information about Data collection and processing</h2>
<h3>System logs and maintenance</h3>
<p>For operation and maintenance purposes, this Application and any third-party services may collect files that record interaction with this Application (System logs) use other Personal Data (such as the IP Address) for this purpose.</p>
<h2>Information not contained in this policy</h2>
<p>More details concerning the collection or processing of Personal Data may be requested from the Owner at any time. Please see the contact information at the beginning of this document.</p>
<h2>How “Do Not Track” requests are handled</h2>
<p>This Application does not support “Do Not Track” requests.</p>
<h2>Changes to this privacy policy</h2>
<p>The Owner reserves the right to make changes to this privacy policy at any time by notifying its Users on this page and possibly within this Application and/or - as far as technically and legally feasible - sending a notice to Users via any contact information available to the Owner. It is strongly recommended to check this page often, referring to the date of the last modification listed at the bottom.</p>
<p>Should the changes affect processing activities performed on the basis of the User’s consent, the Owner shall collect new consent from the User, where required.</p>
    <p>If you have any concerns, then please <a href="mailto:zodiactive@taogidesign.com">contact us</a>.</p>
    <p>
      <Link to="/">Go back to the homepage</Link>
    </p>
  </Layout>
)

export default PrivacyPolicy
